.jw-modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center; 
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  text-align: center;
}
.jw-modal .jw-modal-body {
  word-wrap: break-word;
  display: inline-block;
  padding: 20px;
  background: #fff;
  margin: auto;
  max-width: 1000px;
  min-width: 300px;
}
.jw-modal-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.75;
  z-index: 900;
}
.jw-modal-open {
  overflow: hidden;
}

#username-input {
  font-size: 16px;
  width: 50%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}