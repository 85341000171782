* {
  scrollbar-width: thin;;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

/*#encrypt-container-div {
  align-items: center;
  display: flex;
  height: 100%;
  border-top: 1px solid rgb(178, 178, 178);
}*/

.room{
  width: 100%;
  height: 100%;
}
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%); /* for IE 9 */
  -webkit-transform: translate(-50%, -50%); /* for Safari */
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 20px;
}

html, body, #root {
  height: 100%;
  overflow: hidden;
}

.guide {
  margin: 2%;
}

.activeNavlink {
  background-color: #FF5C42;
}

.header {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: black;
}

.header-link {
  float: left;
}

.header-link a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 16px;
}


/* Page content */
.content {
  padding: 16px;
}

/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky {
  position: fixed;
  top: 0;
  width: 100%
}

/* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
  padding-top: 102px;
}

#attachment-icon {
  cursor: pointer;
  margin: 6px 0px 4px 10px;

}

#lock-icon {
  cursor: pointer;
  width: 32px;
}

#user-icon {
  cursor: pointer;
  float: right;
  max-height: 49px;
}

.msgImg {
  object-fit: contain;
}

.box {
  border-top: 1px solid rgb(178, 178, 178);
  display: flex;
  touch-action: none;
}

#previewImage  {
  object-fit: contain;
}

.close {
  position: absolute;
  right: 32px;
  top: 2px;
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 25px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

#close-div {
  right: 32px;
  top: 2px;
  height: 25px;
  width: 25px;
  position: absolute;
}

.close-btn {
  background: transparent;
  border: none;
  font-size:30px;
  cursor: pointer;
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 3;
}

.admin {
  margin: 5px;
}

#roomCapacity-input {
  font-size: 16px;
}

#username-joinRequest {
  width: 50%;
  font-size: 16px;
}

#room_title {
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

#room-title {
  cursor: pointer;
}

input[type="text"] {
  font-size: 16px;
}

#image_overlay {
  display: none;
  height: 100%;
  width: 100%;
  z-index: 99;
  position: absolute;
  background-color: white;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
}

#roomDropdown a:hover {background-color: #ddd;}

.show {display: block;}